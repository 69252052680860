import { useCallback, useEffect, useRef, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilterContext, type ExternalSource } from '@volvo/vce-package-site-uikit';
import { Button } from '@volvo/vce-uikit';
import { EmptyState } from '../../../components/common/empty-state/EmptyState';
import { CenteredSpinner } from '../../../components/common/loading/CenteredSpinner';
import {
  SortDirection,
  useQueryMaterialFlowsBySiteMapIdLazyQuery,
  useQueryUnassignedSiteLoadTicketsLazyQuery,
  type SiteLoadTicketsFilterBy,
  type SiteLoadTicketsGroupByFlowIdOrderBy,
} from '../../../gql-types/generated-types-super-graph';
import { Center } from '../styles';
import { Filter } from './filter/Filter';
import {
  getIngestionServiceName,
  getIngestionServiceType,
  getSystemTypeFilterValue,
} from './getTypes';
import { GoToFlows } from './go-to-flows/GoToFlows';
import { Container } from './styles';
import { Table } from './table/Table';
import type { SortingState, SourceFlow } from './types';

const defaultSortingState = { id: 'count', desc: true };

export type Props = { siteId: string; companyId?: string; pageSize: number };

export const UnassignedLoadTickets: FC<Props> = ({ siteId, companyId, pageSize }) => {
  const { t } = useTranslation();
  const { filterState } = useFilterContext();

  const [queryUnassingedSiteLoadTickets, { loading: loadingTickets }] =
    useQueryUnassignedSiteLoadTicketsLazyQuery();

  const [queryFlows, { loading: loadingFlowCollection }] =
    useQueryMaterialFlowsBySiteMapIdLazyQuery({
      variables: { siteId },
    });

  const hasMore = useRef(false);
  const currentPage = useRef(0);

  const [loading, setLoading] = useState(true);
  const [sourceFlows, setSourceFlows] = useState<SourceFlow[]>([]);
  const [sortingState, setSortingState] = useState<SortingState>({
    sorting: [defaultSortingState],
  });

  const loadUnassignedTickets = useCallback(
    async (page: number, from?: Date, to?: Date): Promise<SourceFlow[]> => {
      const filterBy: SiteLoadTicketsFilterBy = {
        flowId: null,
        siteId,
      };

      if (filterState.editableState.externalSource !== 'All') {
        filterBy.source = getIngestionServiceName(
          filterState.editableState.externalSource as ExternalSource,
        );
      }

      if (filterState.editableState.searchValue) {
        filterBy.sourceFlowId = filterState.editableState.searchValue;
      }

      if (filterState.editableState.systemType !== 'All') {
        filterBy.scaleType = getSystemTypeFilterValue(filterState.editableState.systemType);
      }

      const validSortValues = ['count', 'sourceTicketFlowId'];

      const orderBy: SiteLoadTicketsGroupByFlowIdOrderBy = validSortValues.includes(
        sortingState.sorting[0].id,
      )
        ? {
            [sortingState.sorting[0].id]: sortingState.sorting[0].desc
              ? SortDirection.Desc
              : SortDirection.Asc,
          }
        : {
            [defaultSortingState.id]: defaultSortingState.desc
              ? SortDirection.Desc
              : SortDirection.Asc,
          };

      const { data } = await queryUnassingedSiteLoadTickets({
        variables: {
          skip: page * pageSize,
          take: pageSize,
          filterBy,
          from,
          to,
          orderBy,
        },
      });

      if (!data?.siteLoadTickets.groupBySourceFlowId.groups) {
        currentPage.current = 0;
        hasMore.current = false;
        return [];
      }

      const flows = await queryFlows();

      const sourceFlows: SourceFlow[] = data.siteLoadTickets.groupBySourceFlowId.groups.map(
        (group) => ({
          sourceTicketFlowId: group.sourceTicketFlowId ?? '-',
          ingestionService: getIngestionServiceType(group.source),
          count: group.count,
          systemType: group.scaleType,
          lastTicket: group.lastTicket,
          connectedFlowName: flows.data?.siteMaps.site?.siteMaterialFlows.edges.find(({ node }) =>
            node.externalReferences?.some(
              (er) =>
                er.value === group.sourceTicketFlowId &&
                er.type === getIngestionServiceType(group.source),
            ),
          )?.node?.name,
        }),
      );

      currentPage.current = page + 1;
      hasMore.current = data.siteLoadTickets.groupBySourceFlowId.hasNextPage;

      return sourceFlows;
    },
    [
      filterState.editableState.externalSource,
      filterState.editableState.searchValue,
      filterState.editableState.systemType,
      pageSize,
      queryFlows,
      queryUnassingedSiteLoadTickets,
      siteId,
      sortingState.sorting,
    ],
  );

  useEffect(() => {
    const from = filterState.editableState.startDate;
    const to = filterState.editableState.endDate;

    setLoading(true);
    setSourceFlows([]);
    loadUnassignedTickets(0, from?.toDate(), to?.toDate())
      .then((result) => {
        setSourceFlows(result);
      })
      .finally(() => setLoading(false));
  }, [filterState.editableState, loadUnassignedTickets]);

  const loadMore = () => {
    loadUnassignedTickets(
      currentPage.current,
      filterState.editableState.startDate?.toDate(),
      filterState.editableState.endDate?.toDate(),
    ).then((result) => {
      setSourceFlows((prev) => [...prev, ...result]);
    });
  };

  return (
    <Container>
      <Filter />
      {companyId ? <GoToFlows siteId={siteId} companyId={companyId} /> : null}
      <Table
        loadTickets={sourceFlows}
        sortingState={sortingState}
        setSortingState={setSortingState}
      />
      {hasMore.current && (
        <Center>
          <Button
            variant="secondary"
            onClick={loadMore}
            disabled={loadingTickets || loadingFlowCollection || !sourceFlows}
          >
            {t('table.load-more-button')}
          </Button>
        </Center>
      )}
      {loading && <CenteredSpinner />}
      {!loading && !sourceFlows.length && (
        <Center>
          <EmptyState />
        </Center>
      )}
    </Container>
  );
};
