import styled from '@emotion/styled';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@volvo/vce-uikit';
import type { HeatmapEventTypes } from '../../../context/heatmap';
import { getColorForEventType } from './helpers';

type EventTypeViewProps = {
  eventType: HeatmapEventTypes;
  count: number;
  status?: string;
};

export const EventTypeView: FC<EventTypeViewProps> = ({ eventType, count, status }) => {
  const { t } = useTranslation();
  const color = getColorForEventType(eventType, status) ?? '#fff';

  return (
    <Wrapper>
      <ColorBlock color={color} />
      <LabelContainer>
        <Typography variant="body">
          {t(`heatmaps.labels.${eventType}${status ? `-${status}` : ''}`)}
        </Typography>
        <Typography variant="button">
          {t('heatmaps.labels.number-registered-events', { count })}
        </Typography>
      </LabelContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
`;

const ColorBlock = styled.div<{ color: string }>`
  height: 26px;
  width: 26px;
  margin: 8px;
  border: 1px solid ${(props) => props.color};
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;

const LabelContainer = styled.div`
  flex-grow: 1;
`;
