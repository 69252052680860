import { type FC, type PropsWithChildren } from 'react';
import { Body, Title } from '../../../typography/Typography';

interface Props extends PropsWithChildren {
  title: string;
  body?: string;
}

export const Row: FC<Props> = ({ title, body = '-', children }) => (
  <>
    <Title text={title} />
    <Body text={body} />
    {children}
  </>
);
