import styled from '@emotion/styled';
import { type FC } from 'react';
import { Spinner, Typography } from '@volvo/vce-uikit';

interface Props {
  leftValue: string;
  leftText: string;
  rightValue?: string;
  rightText?: string;
  leftValueLoading?: boolean;
  rightValueLoading?: boolean;
}

export const BigRow: FC<Props> = ({
  leftValue,
  leftText,
  rightValue,
  rightText,
  leftValueLoading,
  rightValueLoading,
}) => (
  <RowWrapper>
    <div>
      {leftValueLoading ? (
        <Spinner size="small" />
      ) : (
        <Typography variant="subtitle1" color="text">
          {leftValue}
        </Typography>
      )}

      <Typography variant="caption1" color="textSubtle">
        {leftText}
      </Typography>
    </div>
    {rightValue && (
      <div>
        {rightValueLoading ? (
          <Spinner size="small" />
        ) : (
          <Typography variant="subtitle1" color="text">
            {rightValue}
          </Typography>
        )}
        <Typography variant="caption1" color="textSubtle">
          {rightText}
        </Typography>
      </div>
    )}
  </RowWrapper>
);

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  > div {
    flex-grow: 1;
  }
`;
