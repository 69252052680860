import styled from '@emotion/styled';
import { useMemo, type FC } from 'react';
import { useNavigationContext } from '../../../../context/navigation';
import { useSiteContext } from '../../../../context/site';
import { useSiteConfigContext } from '../../../../context/site-config/SiteConfigContext';

const SpeedSign = styled.div<{
  leftPanelOpen: boolean;
  speed: number;
  rightPanelOpen: boolean;
}>`
  position: absolute;
  left: ${(props) => (props.leftPanelOpen ? '360px' : '10px')};
  bottom: 10px;
  border: 8px solid red;
  background: #fff;
  border-radius: 40px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => (Number(props.speed) > 99 ? '32' : '40')}px;
  font-family: 'Volvo Novum';
  z-index: 11;
`;

export const BaseSpeedSign: FC = () => {
  const { siteMap } = useSiteContext();
  const { convertToSiteUnit } = useSiteConfigContext();
  const { leftPanelOpen, rightPanelOpen } = useNavigationContext();

  const speedLimit = useMemo(() => {
    if (!siteMap.baseSpeed) return null;
    return convertToSiteUnit(siteMap.baseSpeed, 'speed');
  }, [siteMap.baseSpeed, convertToSiteUnit]);

  if (!speedLimit) return null;

  return (
    <SpeedSign
      rightPanelOpen={rightPanelOpen}
      leftPanelOpen={leftPanelOpen}
      speed={speedLimit.value}
    >
      {speedLimit?.value}
    </SpeedSign>
  );
};
