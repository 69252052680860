import { useEffect, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotificationBadgeContext } from '@volvo/vce-package-site-uikit';
import { Button } from '@volvo/vce-uikit';
import { EmptyState } from '../../../components/common/empty-state/EmptyState';
import { CenteredSpinner } from '../../../components/common/loading/CenteredSpinner';
import type { ExternalMaterialMappingsHookResult } from '../../../gql-types/generated-types-super-graph';
import { Center } from '../styles';
import { Table } from './table/Table';

export type Props = {
  companyId: string;
  result: ExternalMaterialMappingsHookResult;
};

export const MaterialMapping: FC<Props> = ({
  companyId,
  result: { refetch, fetchMore, data, loading },
}) => {
  const { t } = useTranslation();
  const { setNotificationBadge } = useNotificationBadgeContext();

  const loadMore = () => {
    fetchMore({
      variables: {
        companyId,
        after: data?.siteCompanies.company?.externalMaterialMappings.pageInfo.endCursor,
      },
    });
  };

  useEffect(() => {
    if (!loading && data) {
      setNotificationBadge({
        id: 'material-mapping',
        content:
          data.siteCompanies.company?.externalMaterialMappings.edges.filter(
            ({ node }) => !node.materialVariant,
          ).length ?? 0,
        viewed: true,
      });
    }
  }, [loading, data, setNotificationBadge]);

  const onUpdate = () => {
    refetch({ companyId });
  };

  return (
    <>
      <Table
        data={
          data?.siteCompanies.company?.externalMaterialMappings.edges.map(({ node }) => node) || []
        }
        onUpdate={onUpdate}
        companyId={companyId}
      />
      {loading && <CenteredSpinner />}
      {data?.siteCompanies.company?.externalMaterialMappings.pageInfo.hasNextPage && (
        <Center>
          <Button variant="secondary" onClick={loadMore} disabled={loading}>
            {t('table.load-more-button')}
          </Button>
        </Center>
      )}
      {!loading && !data?.siteCompanies.company?.externalMaterialMappings.edges.length && (
        <Center>
          <EmptyState />
        </Center>
      )}
    </>
  );
};
