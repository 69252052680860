import { createContext, useContext, useState, type FC, type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import {
  autoFormatter,
  autoFormatterToValueUnit,
  convert,
  formattedUnit as formatUnit,
  type Systems,
  type Units,
} from '@volvo/vce-package-units';
import { ErrorMessage } from '../../components/common/error/ErrorMessage';
import { CenteredSpinner } from '../../components/common/loading/CenteredSpinner';
import { useQuerySiteConfigurationsBySiteId } from '../../gql-types/generated-types-super-graph';
import type { SiteUnits } from './convert/types';
import type { SiteConfigContextType, SiteConfigProviderProps } from './types';

const toNumber = (value: string) => parseInt(value.replace(/\s/g, '').replace(/^-0$/, '0'));

// These cosmetic "fixes" of tonnes in vce-package-units breaks further usage of the units
const revertUnitSpelling = (system: Systems, unit: string): string =>
  system === 'metric' && unit === 't'
    ? 'mt'
    : system === 'imperial' && unit === 'tn'
      ? 't'
      : system === 'imperialUK' && unit === 'lt'
        ? 'longt'
        : unit;

const defaultUnits: SiteUnits = {
  speed: {
    system: 'metric',
    base: 'km/h',
    unit: 'km/h',
  },
  mass: {
    system: 'metric',
    base: 'kg',
    unit: 'kg',
    id: 'mt',
  },
  length: {
    system: 'metric',
    base: 'm',
    unit: 'm',
  },
  volume: {
    system: 'metric',
    base: 'm3',
    unit: 'm3',
  },
  volumeFlowRate: {
    system: 'metric',
    base: 'l',
    unit: 'l',
  },
};

const SiteConfigContext = createContext({} as SiteConfigContextType);

export const SiteConfigProvider: FC<PropsWithChildren<SiteConfigProviderProps>> = ({
  siteId,
  children,
}) => {
  const { t } = useTranslation();

  const [units, setUnits] = useState<SiteUnits>(defaultUnits);

  const siteConfig = useQuerySiteConfigurationsBySiteId({
    variables: { siteId },
    pollInterval: parseInt(import.meta.env.VITE_POLL_INTERVAL),
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'standby',
    returnPartialData: true,
    // onCompleted won't be called unless this is set...
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const siteConfigData = data.sites.site?.configuration;

      setUnits({
        speed: {
          system: siteConfigData?.speedUnit.system,
          base: defaultUnits.speed.base,
          unit: siteConfigData?.speedUnit.id ?? defaultUnits.speed.unit,
        } as SiteUnits['speed'],
        mass: {
          system:
            siteConfigData?.weightUnit.id === 'lb'
              ? 'imperialUK'
              : siteConfigData?.weightUnit.id === 't'
                ? 'imperial'
                : 'metric',
          base: defaultUnits.mass.base,
          unit: siteConfigData?.weightUnit.id ?? defaultUnits.mass.unit,
          id: siteConfigData?.weightUnit.id,
        } as SiteUnits['mass'],
        length: {
          system: siteConfigData?.distanceUnit.system,
          base: defaultUnits.length.base,
          unit: siteConfigData?.distanceUnit.id ?? defaultUnits.length.unit,
        } as SiteUnits['length'],
        volume: {
          system: siteConfigData?.volumeUnit.system,
          base: defaultUnits.volume.base,
          unit: siteConfigData?.volumeUnit.id ?? defaultUnits.volume.unit,
        } as SiteUnits['volume'],
        volumeFlowRate: {
          system: siteConfigData?.fluidVolumeUnit.system,
          base: defaultUnits.volumeFlowRate.base,
          unit: siteConfigData?.fluidVolumeUnit.id ?? defaultUnits.volumeFlowRate.unit,
        } as SiteUnits['volumeFlowRate'],
      });
    },
  });

  if (!siteConfig.data && siteConfig.loading) {
    return <CenteredSpinner style={{ height: '100%' }} />;
  }

  if (siteConfig.error) {
    return <ErrorMessage text={t('error')} description={siteConfig.error?.message} />;
  }

  /**
   * Converts a value from base unit to best unit
   */
  const convertToBestUnit: SiteConfigContextType['convertToBestUnit'] = (value, type, options) => {
    const { formattedValue, formattedUnit } = autoFormatterToValueUnit(value, {
      unit: units[type].base,
      system: units[type].system,
      ...options,
      toBest: true,
    });

    const unitFixed = formattedUnit
      ? revertUnitSpelling(units[type].system, formattedUnit)
      : units[type].base;

    const display = autoFormatter(value, {
      unit: units[type].base,
      system: units[type].system,
      ...options,
      toBest: true,
    });

    const { formattedValue: rounded } = autoFormatterToValueUnit(value, {
      unit: units[type].base,
      system: units[type].system,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      toBest: true,
    });

    const roundedDisplay = autoFormatter(value, {
      unit: units[type].base,
      system: units[type].system,
      ...options,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      toBest: true,
    });

    return {
      display,
      value: toNumber(formattedValue),
      unit: (unitFixed as Units) || units[type].unit,
      unitDisplay: formatUnit(unitFixed as Units),
      rounded: toNumber(rounded),
      roundedDisplay,
    };
  };

  /**
   * Converts a value from base unit to site unit
   */
  const convertToSiteUnit: SiteConfigContextType['convertToSiteUnit'] = (value, type, options) => {
    const { unit, base } = units[type];

    const display = autoFormatter(value, {
      unit: base,
      system: units[type].system,
      ...options,
      toBest: false,
      preferredUnit: unit,
    });

    const { formattedValue, formattedUnit } = autoFormatterToValueUnit(value, {
      unit: base,
      system: units[type].system,
      ...options,
      toBest: false,
      preferredUnit: unit,
    });

    const unitFixed = formattedUnit ? revertUnitSpelling(units[type].system, formattedUnit) : unit;

    const { formattedValue: rounded } = autoFormatterToValueUnit(value, {
      unit: base,
      system: units[type].system,
      toBest: false,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      preferredUnit: unit,
    });

    const roundedDisplay = autoFormatter(value, {
      unit: base,
      system: units[type].system,
      ...options,
      toBest: false,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      preferredUnit: unit,
    });

    return {
      display,
      value: toNumber(formattedValue),
      unit: (unitFixed as Units) || unit,
      unitDisplay: formatUnit(unitFixed as Units),
      rounded: toNumber(rounded),
      roundedDisplay,
    };
  };

  /**
   * Converts a value from site unit to base unit
   */
  const convertFromSiteToBaseUnit: SiteConfigContextType['convertFromSiteToBaseUnit'] = (
    value,
    type,
    options,
  ) => {
    const toUnit = units[type].base;
    const result = convert(value).from(units[type].unit).to(toUnit);

    const { formattedValue, formattedUnit } = autoFormatterToValueUnit(result, {
      unit: toUnit,
      ...options,
      toBest: false,
    });

    const unitFixed = formattedUnit
      ? revertUnitSpelling(units[type].system, formattedUnit)
      : toUnit;

    const display = autoFormatter(result, {
      unit: toUnit,
      ...options,
      toBest: false,
    });

    const { formattedValue: rounded } = autoFormatterToValueUnit(result, {
      unit: toUnit,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      toBest: false,
    });

    const roundedDisplay = autoFormatter(result, {
      unit: toUnit,
      ...options,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      toBest: false,
    });

    return {
      display,
      value: toNumber(formattedValue),
      unit: (unitFixed as Units) || toUnit,
      unitDisplay: formatUnit(unitFixed as Units),
      rounded: toNumber(rounded),
      roundedDisplay,
    };
  };

  /**
   * Converts a value from a specified unit to a specified unit
   */
  const convertFromUnitToUnit: SiteConfigContextType['convertFromUnitToUnit'] = (
    value,
    from,
    to,
    type,
    options,
  ) => {
    const result = convert(value).from(from).to(to);

    const { formattedValue, formattedUnit } = autoFormatterToValueUnit(result, {
      unit: from,
      ...options,
      toBest: false,
    });

    const unitFixed = formattedUnit ? revertUnitSpelling(units[type].system, formattedUnit) : to;

    const display = autoFormatter(result, {
      unit: to,
      ...options,
      toBest: false,
    });

    const { formattedValue: rounded } = autoFormatterToValueUnit(result, {
      unit: to,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      toBest: false,
    });

    const roundedDisplay = autoFormatter(result, {
      unit: to,
      ...options,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      toBest: false,
    });

    return {
      display,
      value: toNumber(formattedValue),
      unit: (unitFixed as Units) || to,
      unitDisplay: formatUnit(unitFixed as Units),
      rounded: toNumber(rounded),
      roundedDisplay,
    };
  };

  return (
    <SiteConfigContext.Provider
      value={{
        convertToBestUnit,
        convertToSiteUnit,
        convertFromSiteToBaseUnit,
        convertFromUnitToUnit,
        units,
      }}
    >
      {children}
    </SiteConfigContext.Provider>
  );
};

export const useSiteConfigContext = () => useContext(SiteConfigContext);
