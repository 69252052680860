import styled from '@emotion/styled';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 24px 16px 24px;
  margin-bottom: 8px;
  > div {
    display: flex;
    width: 100%;
    gap: 16px;
    margin-left: 16px;
  }
  border-bottom: 1px solid ${(props) => props.theme.colors.borderSubtle};
`;

export const ColorBlock = styled.div<{ color: string }>`
  height: 26px;
  width: 26px;
  margin-left: 2px;
  border: 1px solid ${(props) => props.color};
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;

export const LabelContainer = styled.div`
  flex-grow: 1;
`;
