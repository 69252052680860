import styled from '@emotion/styled';

export const Grid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px 0;
`;

export const DatesContainer = styled.div<{ paddingLeft: string }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
  padding-left: ${(props) => props.paddingLeft};
  padding-bottom: 24px;
`;

export const Wrapper = styled.div`
  padding: 24px 24px 0 24px;
`;

export const Container = styled.div`
  padding: 0 24px;
`;
