import styled from '@emotion/styled';
import { type FC } from 'react';
import { Spinner, Typography } from '@volvo/vce-uikit';
import type { SemanticColor } from '@volvo/vce-uikit-tokens/dist/web/global/semantic-colors';

interface Props {
  label: string;
  value?: string | number;
  valueColor?: SemanticColor;
  loadingValue?: boolean;
}

export const Row: FC<Props> = ({ label, value, valueColor, loadingValue }) => (
  <RowWrapper>
    <Left variant="body" color="textSubtle">
      {label}
    </Left>
    {loadingValue ? (
      <Spinner size="small" />
    ) : (
      <Typography variant="body" color={valueColor ?? 'text'}>
        {value}
      </Typography>
    )}
  </RowWrapper>
);

const Left = styled(Typography)`
  min-width: 120px;
  width: 120px;
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 8px;
`;
