import bbox from '@mapbox/geojson-extent';
import { point } from '@turf/turf';
import { useLayoutEffect, useState } from 'react';
import { useMap } from 'react-map-gl';
import { useMachineLocationsContext } from '../../../../context/machine-locations';
import { useNavigationContext } from '../../../../context/navigation';

export const CenterMachine = () => {
  const { default: map } = useMap();
  const { selected, fitBounds } = useNavigationContext();
  const { locations } = useMachineLocationsContext();
  const [centeredMachineId, setCenteredMachineId] = useState<string | null>();

  if (selected?.type !== 'machine') {
    throw new Error('Invalid feature type');
  }

  useLayoutEffect(() => {
    if (!selected.machine?.id || centeredMachineId === selected.machine.id) {
      return;
    }

    const { coords } = locations?.get(selected.machine.id)?.position ?? {};

    if (!coords?.latitude || !coords.longitude) {
      return;
    }

    fitBounds({
      bounds: bbox(point([coords.longitude, coords.latitude])),
      ...(map && { options: { zoom: map.getZoom() < 16.1 ? 16.1 : map.getZoom() } }),
    });

    setCenteredMachineId(selected.machine.id);
  }, [fitBounds, locations, map, selected, centeredMachineId]);

  return null;
};
