import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { CustomFeatureAppStartupEvent } from '../../../../types';
import { Row } from './Row';
import { Section } from './Section';

export const ApplicationStarted: FC<{ feature: CustomFeatureAppStartupEvent }> = ({ feature }) => {
  const { t } = useTranslation();

  return (
    <Section title={t('heatmaps.details.labels.app-startup.title')}>
      <Row
        value={feature.properties.isInsideSiteBoundary.toString()}
        label={t('heatmaps.details.labels.app-startup.inside-site-boundary')}
      />
    </Section>
  );
};
