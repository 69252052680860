import { autoFormatter, type Units } from '@volvo/vce-package-units';
import type { SiteUnits } from '../../../../context/site-config/convert/types';
import {
  MaterialFlowType,
  MetricDefinitionType,
  type FragmentPointOfInterestForSiteMaterialFlow,
  type FragmentSiteMaterialFlowWithMetricData,
  type FragmentSiteMetricDefinitionData,
} from '../../../../gql-types/generated-types-super-graph';

export const compareMaterialFamily = (
  a?: FragmentSiteMaterialFlowWithMetricData[],
  b?: FragmentSiteMaterialFlowWithMetricData[],
): number => {
  if (!a || !a.length) return !b || !b.length ? 0 : -1;
  if (!b || !b.length) return 1;

  const aMaterialFamilyName =
    a[0]?.materialVariant?.materialType?.materialFamily?.name.toLowerCase() || '';
  const bMaterialFamilyName =
    b[0]?.materialVariant?.materialType?.materialFamily?.name.toLowerCase() || '';

  if (aMaterialFamilyName < bMaterialFamilyName) return -1;
  if (aMaterialFamilyName > bMaterialFamilyName) return 1;
  return 0;
};

export const getPreferredUnitForFlowRate = (units: SiteUnits): string => {
  return `${units.mass.unit}/h`;
};

export const getMetric = (
  siteMetricDefinitions: FragmentSiteMetricDefinitionData[],
  metricTypes: MetricDefinitionType[],
  units: SiteUnits,
): { metricDefinition?: FragmentSiteMetricDefinitionData; value: string } => {
  const metricDefinition = siteMetricDefinitions.find((t) => metricTypes.includes(t.type));

  if (!metricDefinition) return { metricDefinition: metricDefinition, value: '' };

  const metricData = metricDefinition.currentSiteMetricData;
  const metricType = metricDefinition.type;

  if (metricData) {
    const value = Number.parseFloat(metricData.value);
    switch (metricType) {
      case MetricDefinitionType.MassFlowRate:
      case MetricDefinitionType.FlowRateMass:
        return {
          metricDefinition,
          value: autoFormatter(value, {
            unit: metricData.unit as Units,
            preferredUnit: getPreferredUnitForFlowRate(units) as Units,
          }).replace(/^-0.00|0.00/, '0'),
        };

      case MetricDefinitionType.LevelVolume:
        return {
          metricDefinition,
          value: autoFormatter(value, {
            unit: metricData.unit as Units,
            preferredUnit: units.volume.unit as Units,
          }).replace(/^-0.00|0.00/, '0'),
        };

      case MetricDefinitionType.LevelPercent:
        return {
          metricDefinition,
          value: `${autoFormatter(value, { maximumFractionDigits: 0, minimumFractionDigits: 0 }).replace(/^-0$/, '0')}${metricData.unit}`,
        };
      case MetricDefinitionType.LevelMass:
        return {
          metricDefinition,
          value: autoFormatter(value, {
            unit: metricData.unit as Units,
            preferredUnit: units.mass.unit as Units,
          }).replace(/^-0.00|0.00/, '0'),
        };
      case MetricDefinitionType.ProducedMass:
        return {
          metricDefinition,
          value: autoFormatter(value, {
            unit: metricData.unit as Units,
            preferredUnit: units.mass.unit as Units,
          }).replace(/^-0.00|0.00/, '0'),
        };
    }
  }

  return { metricDefinition, value: '-' };
};

export const getPoiName = (
  materialFlow: FragmentSiteMaterialFlowWithMetricData,
  pointOfInterestData: FragmentPointOfInterestForSiteMaterialFlow[],
): string => {
  if (materialFlow.type === MaterialFlowType.PoiToZone) {
    return pointOfInterestData?.find((poi) => poi.id === materialFlow.fromId)?.name ?? '-';
  }

  if (materialFlow.type === MaterialFlowType.ZoneToPoi) {
    return pointOfInterestData?.find((poi) => poi.id === materialFlow.toId)?.name ?? '-';
  }
  return '-';
};
