import styled from '@emotion/styled';
import type { FC, PropsWithChildren } from 'react';
import { Typography } from '@volvo/vce-uikit';

type Props = {
  title: string;
};
export const Section: FC<PropsWithChildren<Props>> = ({ title, children }) => {
  return (
    <Wrapper>
      <Title variant="subtitle2">{title}</Title>
      <Content>{children}</Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 18px;
`;

const Title = styled(Typography)`
  padding: 0 8px 16px 8px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 16px;
`;

const Content = styled.div`
  margin-left: 16px;
`;
