import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@volvo/vce-uikit';
import { useNavigationContext } from '../../../context/navigation';
import { ButtonWrapper } from '../styles';

interface Props {
  onEdit: () => void;
  showOnMap?: boolean;
}

export const EditButton: FC<Props> = ({ onEdit, showOnMap = true }) => {
  const { t } = useTranslation();
  const { isMobile, isTabletPortrait, setMobileDrawerExpanded, setLeftPanelOpen } =
    useNavigationContext();

  return (
    <ButtonWrapper id="edit-button" style={{ width: '100%' }}>
      <Button variant="secondary" onClick={onEdit} iconStart="edit">
        {t('details-panel.edit')}
      </Button>
      {(isMobile || isTabletPortrait) && showOnMap && (
        <Button
          variant="secondary"
          onClick={() => {
            setMobileDrawerExpanded(false);
            setLeftPanelOpen(false);
          }}
        >
          {t('show-on-map')}
        </Button>
      )}
    </ButtonWrapper>
  );
};
