import styled from '@emotion/styled';
import { theme } from '../../../theme';

export const Wrapper = styled.div<{
  isMobile: boolean;
  isTabletPortrait: boolean;
  expanded: boolean;
  width: number;
}>`
  position: absolute;
  ${(props) => (props.isTabletPortrait ? 'left: 0;' : 'right: 0;')}
  ${(props) => ((props.isMobile || props.isTabletPortrait) && !props.expanded ? 'bottom: 0' : '')};
  display: grid;
  grid-template-rows: auto 1fr;
  height: ${(props) =>
    (props.isMobile || props.isTabletPortrait) && !props.expanded ? '80px' : '100%'};
  width: ${(props) => (props.isMobile ? '100%' : `${props.width}px`)};
  background-color: ${theme.colors.bg};
  z-index: 3;
  ${(props) =>
    (props.isMobile || props.isTabletPortrait) && !props.expanded
      ? 'border-radius: 16px;  border-bottom-left-radius: 0;  border-bottom-right-radius: 0;'
      : ''};
`;

export const Content = styled.div<{
  isMobile: boolean;
  isTabletPortrait: boolean;
  expanded: boolean;
  tabs: boolean;
}>`
  display: ${(props) =>
    (!props.isMobile && !props.isTabletPortrait) || props.expanded ? 'grid' : 'none'};
  overflow-y: auto;
  grid-template-rows: ${({ tabs }) => (tabs ? 'auto 1fr auto' : '1fr auto')};
`;

export const HeaderContainer = styled.div<{ leftIcon: boolean; mobileDrawerExpanded: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.leftIcon ? '24px 1fr 24px' : '1fr 24px')};
  align-items: center;
  column-gap: 16px;
  margin: 16px;
  margin-top: ${(props) => (props.mobileDrawerExpanded ? '16px' : '24px')};
`;

export const TitleWrapper = styled.div<{ mobileDrawerExpanded: boolean }>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  ${(props) => (props.mobileDrawerExpanded ? 'justify-content: center' : 'justify-content: start')};
  overflow: hidden;
`;

export const VerticalChevronContainer = styled.div<{
  isTabletPortrait: boolean;
  panelWidth: number;
}>`
  background-color: transparent;
  bottom: 80px;
  width: 100%;
  z-index: 2;
  position: absolute;
  display: flex;
  ${(props) =>
    props.isTabletPortrait ? `left: ${props.panelWidth / 2 - 34}px;` : 'justify-content: center;'}
`;

export const Chevron = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 68px;
  height: 25px;
  border: 1px solid #ededed;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;
