import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryMaterialVariantsById } from '../../../../gql-types/generated-types-super-graph';
import { BigRow } from './BigRow';

export const LoadOffload: FC<{ weight: string; materialVariantId: string | undefined }> = ({
  weight,
  materialVariantId,
}) => {
  const { t } = useTranslation();
  const { data, loading } = useQueryMaterialVariantsById({
    variables: { id: materialVariantId ?? '' },
    skip: !materialVariantId,
  });

  return (
    <BigRow
      leftValue={weight}
      leftText={t('heatmaps.details.labels.load-offload.weight')}
      rightValue={data?.materialVariants.materialVariant?.name ?? '-'}
      rightText={t('heatmaps.details.labels.load-offload.material')}
      rightValueLoading={loading}
    />
  );
};
