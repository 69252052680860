import styled from '@emotion/styled';
import type { CSSProperties, FC, PropsWithChildren } from 'react';
import { useNavigationContext } from '../../../context/navigation';

export const Overlay: FC<PropsWithChildren> = ({ children }) => {
  const { rightPanelWidth, leftPanelWidth } = useNavigationContext();

  const offset = leftPanelWidth - rightPanelWidth;

  const style: CSSProperties = {};

  if (offset > 0) {
    style['paddingLeft'] = `${offset}px`;
  } else if (offset < 0) {
    style['paddingRight'] = `${-offset}px`;
  }

  return <OverlayContainer style={style}>{children}</OverlayContainer>;
};

const OverlayContainer = styled.div`
  position: absolute;

  width: 100%;
  height: calc(100% + 44px);
  top: -44px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;
