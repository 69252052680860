import styled from '@emotion/styled';
import { useEffect, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Typography } from '@volvo/vce-uikit';
import { useFeaturesContext } from '../../../context/features';
import {
  createLoadTicketFeature,
  createTripFeature,
} from '../../../context/features/createFeatures';
import { Trip } from '../../../context/heatmap';
import {
  getEmptyHeatmapFeatures,
  useHeatmapContext,
} from '../../../context/heatmap/HeatmapContext';
import { OverlayContent } from '../../../context/heatmap/OverlayContent';
import { useNavigationContext } from '../../../context/navigation';
import { useSiteContext } from '../../../context/site';
import { useSiteConfigContext } from '../../../context/site-config/SiteConfigContext';
import {
  FragmentSiteDeviceEvents,
  FragmentSiteLoadTicket,
  FragmentTrip,
  SiteDeviceEventType,
  SortDirection,
  useQuerySiteDeviceEventsLazyQuery,
  useQuerySiteLoadTicketsLazyQuery,
  useQueryTripsLazyQuery,
  type InputMaybe,
  type SiteDeviceEventFilterInput,
} from '../../../gql-types/generated-types-super-graph';
import type { CustomFeature, CustomFeatureLoadTicket, CustomFeatureTrip } from '../../../types';
import { Overlay } from '../../common/overlay/Overlay';
import { Container } from '../container/Container';
import { ButtonWrapper } from '../styles';
import { Details } from './Details';
import { Error as ErrorControl } from './Error';
import { mapEvents } from './eventMapper';
import { Filter } from './Filter';
import { getLoadTicketsData } from './old/load-tickets/getLoadTicketsData';
import { getTripsData } from './old/trips/getTripsData';
import { Overview } from './Overview';
import type { HeatmapFeatures } from './types';

const NUMBER_OF_EVENTS = 2000;
const TOTAL_NUMBER_OF_EVENTS = 100000;

export const NewHeatMaps: FC = () => {
  const { t } = useTranslation();
  const { selected, setSelected } = useNavigationContext();
  const { units } = useSiteConfigContext();
  const { siteId } = useSiteContext();
  const { featureCollection, heatmapCollection, setHeatmapCollection } = useFeaturesContext();
  const {
    close,
    filterValues,
    loading,
    setLoading,
    page,
    setPage,
    removeHeatmapEvents,
    setHeatmapContextCollection,
    setToManyEvents,
  } = useHeatmapContext();

  const [queryDeviceEvents, { fetchMore }] = useQuerySiteDeviceEventsLazyQuery();
  const [queryLoadTickets] = useQuerySiteLoadTicketsLazyQuery();
  const [queryTrips] = useQueryTripsLazyQuery();
  const [hasError, setHasError] = useState(false);
  const [noResults, setHasNoResults] = useState(false);
  const [showConfirmClose, setShowConfirmClose] = useState(false);

  const loadDeviceEventsPage = async (
    filterBy: InputMaybe<SiteDeviceEventFilterInput>,
    cursor: string | null,
  ): Promise<{
    hasMorePages: boolean;
    events: FragmentSiteDeviceEvents[];
    cursor: string | null;
  }> => {
    let page: any;
    if (cursor) {
      const result = await fetchMore({
        variables: {
          siteId,
          filterBy,
          orderBy: { createdAt: SortDirection.Desc },
          first: NUMBER_OF_EVENTS,
          cursor,
        },
      });

      page = result.data?.siteDeviceEventQueries?.siteDeviceEvents;
    } else {
      const result = await queryDeviceEvents({
        variables: {
          siteId,
          filterBy,
          orderBy: { createdAt: SortDirection.Desc },
          first: NUMBER_OF_EVENTS,
        },
        onError: () => setHasError(true),
      });

      page = result.data?.siteDeviceEventQueries?.siteDeviceEvents;
    }

    if (!page) {
      return { hasMorePages: false, cursor: null, events: [] };
    }

    return {
      hasMorePages: page.pageInfo.hasNextPage,
      cursor: page.pageInfo.endCursor ?? null,
      events: page.edges,
    };
  };

  const loadDeviceEvents = async () => {
    const deviceTypes = filterValues.eventTypes.filter((x) => x !== 'TRIP');
    const filterBy = {
      types: deviceTypes,
      from: filterValues.fromDate === '' ? undefined : filterValues.fromDate,
      to: filterValues.toDate === '' ? undefined : filterValues.toDate,
    };

    const events: FragmentSiteDeviceEvents[] = [];

    let loadMore = true;
    let nextCursor: string | null = null;

    while (loadMore) {
      const {
        hasMorePages: harMorePages,
        cursor,
        events: pageEvents,
      } = await loadDeviceEventsPage(filterBy, nextCursor);

      events.push(...pageEvents);
      nextCursor = cursor;
      loadMore = harMorePages && events.length < TOTAL_NUMBER_OF_EVENTS;
    }

    setToManyEvents(events.length >= TOTAL_NUMBER_OF_EVENTS);

    const newFeatures = mapEvents(events, units, featureCollection);
    return newFeatures;
  };

  const loadLoadTicketEvents = async () => {
    const result = await queryLoadTickets({
      variables: {
        siteId,
        from: filterValues.fromDate === '' ? undefined : filterValues.fromDate,
        to: filterValues.toDate === '' ? undefined : filterValues.toDate,
        first: NUMBER_OF_EVENTS,
        system: units.mass.system,
        orderBy: { createdAt: SortDirection.Asc },
      },
    });
    const siteLoadTickets: FragmentSiteLoadTicket[] =
      result.data?.siteLoadTickets?.siteLoadTickets.edges.map((edge) => edge.node) ?? [];
    const loadTicketsData = getLoadTicketsData(siteLoadTickets, featureCollection);
    const loadTicketsFeatures = loadTicketsData.map(createLoadTicketFeature);

    return loadTicketsFeatures;
  };

  const loadTrips = async () => {
    const result = await queryTrips({
      variables: {
        filterBy: {
          createdAt: {
            gte: filterValues.fromDate === '' ? undefined : filterValues.fromDate,
            lte: filterValues.toDate === '' ? undefined : filterValues.toDate,
          },
          siteId: { equals: siteId },
        },
        first: NUMBER_OF_EVENTS,
        orderBy: { createdAt: SortDirection.Desc },
      },
    });
    const trips: FragmentTrip[] = result.data?.trips?.trips.edges.map((edge) => edge.node) ?? [];
    const tripsData = getTripsData(trips);
    const tripsFeatures = tripsData.map(createTripFeature);

    return tripsFeatures;
  };

  const loadFlowEvents = async () => {
    let loadTickets: CustomFeatureLoadTicket[] = [];
    let trips: CustomFeatureTrip[] = [];
    if (filterValues.eventTypes.includes(SiteDeviceEventType.ManualLoadTicket)) {
      loadTickets = await loadLoadTicketEvents();
    }
    if (filterValues.eventTypes.includes(Trip.Trip)) {
      trips = await loadTrips();
    }

    return { loadTickets, trips };
  };

  const loadEvents = async () => {
    removeHeatmapEvents();
    setHasError(false);
    setHasNoResults(false);
    let newFeatures: HeatmapFeatures;
    const features: CustomFeature[] = [];
    if (filterValues.visualizationType === 'flow') {
      const { loadTickets, trips } = await loadFlowEvents();
      newFeatures = getEmptyHeatmapFeatures();

      if (loadTickets.length) {
        newFeatures[SiteDeviceEventType.ManualLoadTicket] = loadTickets;
      }

      if (trips.length) {
        newFeatures[Trip.Trip] = trips;
      }
    } else {
      newFeatures = await loadDeviceEvents();

      filterValues.eventTypes.forEach((eventType) => {
        features.push(...newFeatures[eventType]);
      });

      setHeatmapCollection(features);
      setHeatmapContextCollection(newFeatures);
    }

    setHasNoResults(!hasError && features.length === 0);
    if (features.length > 0) {
      setPage('OVERVIEW');
    }
    setLoading(false);
  };

  let headerText: string;

  switch (page) {
    case 'FILTER':
      headerText = t('heatmaps.headers.filter');
      break;
    case 'OVERVIEW':
      headerText = t('heatmaps.headers.overview');
      break;
    case 'DETAILS':
      headerText = t('heatmaps.headers.details');
      break;
    default:
      throw new Error('Invalid page');
  }

  useEffect(() => {
    if (selected?.type === 'feature' && selected.feature.properties.heatmap) {
      setPage('DETAILS');
    }
  }, [selected, setPage]);

  const onBack =
    page === 'FILTER'
      ? undefined
      : page === 'DETAILS'
        ? () => {
            setSelected({
              type: 'heatmap',
            });
            setPage('OVERVIEW');
          }
        : () => {
            setSelected({
              type: 'heatmap',
            });
            setPage('FILTER');
          };

  const confirmClose = () => {
    if (heatmapCollection.length) {
      setShowConfirmClose(true);
      return;
    }
    onClose();
  };

  const onClose = () => {
    removeHeatmapEvents();
    close();
  };

  return (
    <>
      <Container onBack={onBack} onClose={confirmClose} headerTitle={headerText} tabs={false}>
        <HeatmapContainer>
          {hasError && <ErrorControl />}
          {page === 'FILTER' && <Filter noResult={noResults} />}
          {page === 'OVERVIEW' && <Overview />}
          {page === 'DETAILS' && <Details />}
        </HeatmapContainer>
        {page === 'FILTER' && (
          <ButtonWrapper
            style={{ gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', padding: '24px 16px' }}
          >
            <Button variant="secondary" onClick={confirmClose}>
              {t('heatmaps.filter.buttons.close')}
            </Button>
            <Button
              disabled={filterValues.eventTypes.length === 0}
              variant="primary"
              onClick={() => {
                setLoading(true);
                loadEvents();
              }}
            >
              {t('heatmaps.filter.buttons.generate')}
            </Button>
          </ButtonWrapper>
        )}
      </Container>
      {loading && (
        <Overlay>
          <OverlayContent />
        </Overlay>
      )}
      <Dialog open={showConfirmClose}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <Typography variant="heading3">{t('heatmaps.close-dialog.title')}</Typography>
          <Typography variant="body">{t('heatmaps.close-dialog.body')}</Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap: 8,
              marginTop: 8,
            }}
          >
            <Button variant="secondary" autoFocus={true} onClick={() => setShowConfirmClose(false)}>
              {t('heatmaps.close-dialog.cancel-button')}
            </Button>
            <Button variant="primary" onClick={onClose}>
              {t('heatmaps.close-dialog.leave-button')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const HeatmapContainer = styled.div`
  margin: 0 0px;
  padding: 32px 0 0 0;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.colors.borderSubtle};
`;
