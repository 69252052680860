import styled from '@emotion/styled';
import { theme } from '../../../theme';

export type PanelPosition = 'left' | 'right';

const minimized = 'min-height: 55px; bottom: 0; overflow: visible;';
const expanded = 'height: 100%;';

export const Panel = styled.div<{
  open: boolean;
  mobile: boolean;
  tabletPortrait: boolean;
  width: number;
  position: PanelPosition;
  isMinimized?: boolean;
}>`
  position: absolute;
  ${(props) => (props.position === 'right' ? 'right: 0;' : 'left: 0;')}
  padding: 0;
  display: ${(props) => (props.open ? 'none' : 'block')};
  align-content: start;
  overflow: auto;
  ${(props) => (props.isMinimized ? minimized : expanded)};
  width: ${(props) => (props.mobile ? '100%' : `${props.width}px`)};
  background-color: ${theme.colors.bg};
  z-index: ${({ position }) => (position === 'right' ? 3 : 2)};
`;
