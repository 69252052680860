import { useCallback, useState, type FC, type PropsWithChildren } from 'react';
import { SystemIcon, type SystemIconId } from '@volvo/vce-uikit';
import { Button, ButtonWrapper, CollapsibleDiv } from './styles';

interface CollapsibleProps {
  open?: boolean;
  vceIconName: SystemIconId;
}

export const Collapsible: FC<PropsWithChildren<CollapsibleProps>> = ({
  open,
  vceIconName,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleFilterOpening = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <ButtonWrapper>
      <Button onClick={handleFilterOpening}>
        <SystemIcon filled={true} icon={vceIconName} size={16} />
      </Button>
      <CollapsibleDiv>{isOpen && children}</CollapsibleDiv>
    </ButtonWrapper>
  );
};
