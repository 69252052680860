import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { theme, VerticalSpacing } from '@volvo/vce-package-site-uikit';
import { SystemIcon, Tooltip, Typography } from '@volvo/vce-uikit';
import { Row } from './Row';
import { ColorBlock, HeaderWrapper, LabelContainer } from './styles';

type Props = {
  color: string;
  secondColor?: string;
  title: string;
  infoText: string;
  date: string;
  timestamp: string;
  zoneName: string;
};
export const DetailsHeader: FC<Props> = ({
  color,
  title,
  infoText,
  date,
  timestamp,
  zoneName,
  secondColor,
}) => {
  const [infoRef, setInfoRef] = useState<HTMLElement | null>(null);
  const [loadTicketsInfoOpen, setLoadTicketsInfoOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <HeaderWrapper>
      <VerticalSpacing spacingBottom={32}>
        {secondColor ? (
          <MultiColorBlock color={color} secondColor={secondColor} />
        ) : (
          <ColorBlock color={color ?? '#FFF'} />
        )}
        <LabelContainer>
          <Typography variant="heading4">{title}</Typography>
        </LabelContainer>
        <Tooltip
          reference={infoRef}
          open={loadTicketsInfoOpen}
          setOpen={setLoadTicketsInfoOpen}
          placement="left"
          offset={10}
        >
          {infoText}
        </Tooltip>

        <div ref={(ref) => setInfoRef(ref)} style={{}}>
          <SystemIcon icon="info" color={theme.colors.bgButtonMarketing} size={24} />
        </div>
      </VerticalSpacing>
      <Row label={t('heatmaps.details.labels.date')} value={date} />
      <Row label={t('heatmaps.details.labels.timestamp')} value={timestamp} />
      <Row label={t('heatmaps.details.labels.zone')} value={zoneName} />
    </HeaderWrapper>
  );
};

const MultiColorBlock: FC<{ color: string; secondColor: string }> = ({ color, secondColor }) => {
  return (
    <ColorBlock color={color}>
      <div
        style={{
          backgroundColor: secondColor,
          width: '13px',
          height: '26px',
          border: `1px solid ${secondColor}`,
          borderTopLeftRadius: '2px',
          borderBottomLeftRadius: '2px',
          margin: '-1px 0 -1px -1px',
        }}
      />
    </ColorBlock>
  );
};
