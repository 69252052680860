import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { theme, VerticalSpacing } from '@volvo/vce-package-site-uikit';
import { Typography } from '@volvo/vce-uikit';
import { useHeatmapContext } from '../../../context/heatmap';
import type { HeatmapEventTypes } from '../../../context/heatmap/types';
import { SiteDeviceEventType } from '../../../gql-types/generated-types-super-graph';
import type { CustomFeature, CustomFeatureFaultyUnload } from '../../../types';
import { EventTypeView } from './EventTypeView';
import { Container, DatesContainer } from './styles';

export const Overview: FC = () => {
  const { t } = useTranslation();
  const { filterValues, heatmapContextCollection, toManyEvents } = useHeatmapContext();

  return (
    <Container>
      <DatesContainer paddingLeft="8px">
        <Date label={t('heatmaps.overview.labels.start-date')} value={filterValues.fromDate} />
        <Date label={t('heatmaps.overview.labels.end-date')} value={filterValues.toDate} />
      </DatesContainer>{' '}
      <div style={{ borderBottom: `1px solid ${theme.colors.borderSubtle}`, paddingLeft: '8px' }}>
        <Typography
          variant="subtitle2"
          style={{ color: theme.colors.textSubtle, paddingBottom: '12px' }}
        >
          {t('heatmaps.overview.labels.subtitle')}
        </Typography>
      </div>
      {toManyEvents && (
        <VerticalSpacing spacing={8}>
          <Typography variant="subtitle1" color={'textError'}>
            {t('heatmaps.overview.labels.to-many-events')}
          </Typography>
        </VerticalSpacing>
      )}
      <VerticalSpacing spacing={24}>
        {filterValues.eventTypes.map((eventType: HeatmapEventTypes) => {
          const events = heatmapContextCollection[eventType];

          if (eventType === SiteDeviceEventType.WrongOffloadZone) {
            const warnings: CustomFeature[] = [];
            const errors: CustomFeature[] = [];
            events?.forEach((event) => {
              const e = event as CustomFeatureFaultyUnload;
              if (e.properties.status_string === 'WARNING') {
                warnings.push(event);
              } else {
                errors.push(event);
              }
            });

            return (
              <>
                <EventTypeView
                  key={eventType}
                  eventType={eventType}
                  count={warnings.length}
                  status="WARNING"
                />
                <EventTypeView
                  key={eventType}
                  eventType={eventType}
                  count={errors.length}
                  status="ERROR"
                />
              </>
            );
          }

          return (
            <EventTypeView
              key={eventType}
              eventType={eventType}
              count={events ? events.length : 0}
            />
          );
        })}
      </VerticalSpacing>
    </Container>
  );
};

const Date: FC<{
  label: string;
  value: string;
}> = ({ label, value }) => {
  return (
    <div style={{ marginTop: '8px' }}>
      <Typography variant="subtitle1">{value.length ? value : '-'}</Typography>
      <Typography variant="caption1">{label}</Typography>
    </div>
  );
};
