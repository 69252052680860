import styled from '@emotion/styled';
import { Button } from '@volvo/vce-uikit';
import { theme } from '../../../../../theme';

export const Container = styled.div`
  background-color: ${theme.colors.bgSelectedSubtle};
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-right: -24px;
  margin-left: -24px;
  border-bottom: 1px solid ${theme.colors.border};
`;

export const ButtonContainer = styled.div`
  float: right;
  justify-content: end;
  margin-top: 20px;
`;

export const StyledButton = styled(Button)``;
